/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // new WOW().init();
        //console.log('hello');
        jQuery(document).ready(function($){
          jQuery(".steps-carousel").owlCarousel({
          loop: false,
          nav: true,
          navContainer: '.arrow-nav-steps',
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true,
            items: 1,
            center: true,
            margin: 80,
            dots: true,
          },
          // breakpoint from 768 up
          992 : {
            nav: true,
            items: 3,
            center: false,
            margin: 30,
            }
          }

          });

          jQuery(".flexible-carousel").owlCarousel({
          loop: false,
          nav: true,
          navContainer: '.arrow-nav-flexible',
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true,
            items: 1,
            center: true,
            margin: 80,
            dots: true,
          },
          // breakpoint from 768 up
          992 : {
            nav: true,
            items: 3,
            center: false,
            margin: 30,
            }
          }

          });

          jQuery(".terms-carousel").owlCarousel({
          items: 1,
          loop: false,
          dots: false,
          nav: true,
          center: true,
          margin: 80,
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true
          },
          // breakpoint from 768 up
          737 : {
            nav: true
          }
          }
          });
          jQuery(".journeys-carousel").owlCarousel({
          items: 1,
          autoplay: false,
          loop: false,
          dots: false,
          nav: true,
          center: true,
          margin: 80,
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true
          },
          // breakpoint from 768 up
          737 : {
            nav: true
          }
          }
          });
          var tripCarousel = jQuery(".trip-carousel").owlCarousel({
          items: 1,
          autoplay: false,
          loop: false,
          dots: true,
          dotsContainer: '.dots-nav-trip',
          nav: true,
          navContainer: '.arrow-nav-trip',
          center: true,
          margin: 0,
          //animateOut: 'fadeOut',
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true
          },
          // breakpoint from 768 up
          737 : {
            nav: true
          }
          }
          });

          var causeCarousel = jQuery(".cause-carousel").owlCarousel({
          items: 1,
          autoplay: false,
          loop: false,
          dots: true,
          dotsContainer: '.dots-nav-cause',
          nav: true,
          navContainer: '.arrow-nav-cause',
          center: true,
          margin: 0,
          //animateOut: 'fadeOut',
          responsiveClass:true,
          responsive : {
          // breakpoint from 0 up
          0 : {
            nav: true
          },
          // breakpoint from 768 up
          737 : {
            nav: true
          }
          }
          });

          causeCarousel.on('changed.owl.carousel', function(e) {
            var dotCausesMarkup = jQuery( ".dots-nav-cause" ).first().html();
              // console.log("changed");
              jQuery( ".dots-nav-cause" ).each(function( index ) {
                console.log( index + ": " + $( this ).html() );
                if (index !== 0 ) {
                  $( this ).html(dotCausesMarkup);
                }
              });
              //console.log(jQuery( ".dots-nav" ).html());
          });

          tripCarousel.on('changed.owl.carousel', function(e) {
            var dotsTripMarkup = jQuery( ".dots-nav-trip" ).first().html();
              // console.log("changed");
              jQuery( ".dots-nav-trip" ).each(function( index ) {
                console.log( index + ": " + $( this ).html() );
                if (index !== 0 ) {
                  $( this ).html(dotsTripMarkup);
                }
              });
              //console.log(jQuery( ".dots-nav" ).html());
          });

        });
        jQuery(document).ready(function($){
          $('.terms-carousel').each(function(){
            var highestBox = 0;

            $(this).find('.owl-item').each(function(){
              if($(this).height() > highestBox){
                highestBox = $(this).height();
              }
            });
            $(this).find('.owl-item').height(highestBox);
          });
        });

        jQuery(document).ready(function($) {
          $('#countdown').countdown('2020/5/1').on('update.countdown', function(event) {
          var $this = $(this).html(event.strftime(''
          + '%D Days <span>|</span> %H Hrs <span>|</span> %M Mins <span>|</span> %S Secs'));
          });
        });

        jQuery(document).ready(function($) {
          $("#nav-trigger").click(function() {
            $("nav.off-canvas").fadeIn();
          });
          $("#close-nav").click(function() {
            $("nav.off-canvas").fadeOut();
          });
          $("a").on('click', function(event) {
            if (this.hash !== "") {
              event.preventDefault();
              var hash = this.hash;
              $('html, body').animate({
              scrollTop: $(hash).offset().top
              }, 800, function(){
              window.location.hash = hash;
              });
            }
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
